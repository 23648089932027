.price {
  background-image: url(../../../img/fon2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
  padding-bottom: 35px;
}
.price__fon {
  max-width: 1260px;
  padding: 25px;
  background-color: azure;
  margin: 0 auto;
  border-radius: 10px;
}
.container__title {
  color: rgb(205, 5, 5);
  border: 1px solid black;
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
}
.allitems {
  padding: 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 20px;
}
.standartcleaning {
  background-color: white;
  border: 1px solid black;
  padding: 25px;
  border-radius: 10px;
}
.standartcleaning h2 {
  display: inline-block;
  border-bottom: 2px solid black;
  margin-bottom: 10px;
}
.standartcleaning h3 {
  margin: 10px 0;
}
.standartcleaning ul {
  list-style-type: circle;
}

.about-price {
  border-radius: 10px;
  width: 70%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-top: 10px;
  font-size: 18px;
}
.pswashwindows {
  margin-top: 10px;
}
.outsidewidthtable table {
  background-color: azure;
}
.price-title {
  padding: 15px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}
.price-title h1 {
  text-align: center;
  color: rgb(21, 4, 62);
}
@media (max-width: 800px) {
  .allitems {
    padding: 5px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex-wrap: wrap;
    margin: 0 auto;
    gap: 20px;
  }
  .standartcleaning h2 {
    font-size: 17px;
    display: inline-block;
    border-bottom: 2px solid black;
    margin-bottom: 10px;
  }
  .standartcleaning h3 {
    font-size: 17px;
  }
  .price__fon {
    padding: 10px;
  }
}
@media (max-width: 320px) {
  .standartcleaning h2 {
    font-size: 14px;
    display: inline-block;
    border-bottom: 2px solid black;
    margin-bottom: 10px;
  }
  .standartcleaning h3 {
    font-size: 14px;
  }
  .price__fon {
    padding: 10px;
  }
  .container__title {
    font-size: 14px;
  }
  .container-300px {
    padding: 0;
  }
  .about-price {
    border-radius: 10px;
    width: 100%;
    margin-top: 10px;
    font-size: 18px;
  }
  .standartcleaning li {
    font-size: 14px;
  }

  .pswashwindows {
    font-size: 14px;
  }
}
