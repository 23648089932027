.header__fixed {
  opacity: 1;
  position: fixed;
  width: 100%;
  z-index: 10;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.1);
  /* backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px); */
  background-color: white;
}
.header__container-links {
  opacity: 1;
  position: relative;
  z-index: 10;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
/* menu and logo */
.header__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 35px;
}
.header__list-social-media {
  display: flex;
  gap: 5px;
}
.header__links {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  transition: 0.4s;
}
.header__links-scroll {
  display: flex;
  justify-content: flex-end;
  padding: 0px;
  transition: 0.4s;
}
.header__menu {
  background-image: linear-gradient(
    to right,
    rgb(246, 250, 255),
    white 18%,
    rgb(230, 245, 248) 80%,
    white
  );
  position: relative;
  z-index: 2;
}
.isHeaderPhoneSchedule {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-self: center;
}

.backdropMenu {
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.hidden {
  display: none;
}
