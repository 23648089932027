.main-first {
  padding-bottom: 45px;
  background-image: url(../../../img/fonSmall.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
}
/* .main__important {
  background-color: aliceblue;
  padding: 30px;
} */
