/* forma */
form {
  display: block;
  max-width: 700px;
  /* background-color: #163687; */
  background-color: #264caa;
  margin: 0 auto;
  border-radius: 5px;
  padding: 25px;
  margin-bottom: 20px;
}
form h2 {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  max-width: 310px;
  margin: 40px auto;
  text-align: center;
}
input,
.btn {
  display: block;
  background-color: #ffffff;
  width: 90%;
  height: 60px;
  margin: 0 auto;
}
input {
  margin-bottom: 35px;
  padding-left: 17px;
  font-size: 14px;
  border-radius: 5px;
}
input:focus {
  border: 2px solid #00a6ff;
  font-size: 18px;
  /* color: #ff0000; */
}
.form__btn {
  padding-bottom: 20px;
}
.btn {
  max-width: 200px;
  text-align: center;
  background-color: #fed133;
  color: #000;
  font-weight: 800;
  font-size: 16px;
  cursor: pointer;
  padding: 0;
}
