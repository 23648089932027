.about {
  background-image: url(../../../img/fon2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
  padding-bottom: 35px;
}
.about-title {
  padding: 15px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.about-title h1 {
  text-align: center;
  color: rgb(21, 4, 62);
}

.about__content h2 {
  color: rgb(21, 4, 62);
  padding: 35px 0;
  text-align: center;
}
.about__content-img {
  max-width: 500px;
  margin: 0 auto;
}
.about__content-img img {
  width: 100%;
  border-radius: 10px;
}
.about__content-text {
  margin: 35px auto 0 auto;
  padding: 25px 15px;
  max-width: 1240px;
  background-color: azure;
  border-radius: 10px;
}
.about__content-text p {
  padding: 10px 10px;
  margin: 0;
  text-align: justify;
  font-size: 18px;
  color: rgb(21, 4, 62);
}
@media (max-width: 600px) {
  .about__content h2 {
    font-size: 18px;
  }
  .about__content-text p {
    font-size: 16px;
  }
}
@media (max-width: 440px) {
  .about__content h2 {
    font-size: 15px;
  }
  .about__content-text p {
    font-size: 13px;
    text-align: left;
  }
}
@media (max-width: 356px) {
  .about__content h2 {
    font-size: 13px;
  }
  .about__content-text p {
    font-size: 12px;
    padding: 5px 5px;
  }
}
