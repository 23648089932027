.email__link:hover {
  color: rgb(91, 118, 163);
}
.email {
  align-self: center;
  margin-right: 10px;
}
@media (max-width: 650px) {
  .email__link {
    font-size: 16px;
    padding-bottom: 10px;
  }
}
@media (max-width: 350px) {
  .email__link {
    font-size: 14px;
  }
  .email {
    margin-right: 0px;
  }
}
@media (max-width: 304px) {
  .email__link {
    font-size: 12px;
  }
}
