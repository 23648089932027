.logo {
  display: block;
  width: 100px;
  height: 100px;
}
.logo__img {
  transform: scale(1.3);
  border-radius: 30%;
}
@media (max-width: 480px) {
  .logo__img {
    transform: scale(1);
  }
}
