.header__phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 2px solid black; */
}

.header-phone-item {
  padding: 15px;
  padding-left: 10px;
  margin: 0;
  width: 100%;
}
.header-phone-item:hover {
  color: rgb(91, 118, 163);
}

@media (max-width: 550px) {
  .header__phone-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* border: 2px solid black; */
  }
  .header__phone-img {
    scale: 0.8;
  }
  .header-phone-item {
    padding: 5px;
    padding-left: 5px;
    font-size: 16px;
  }
}
@media (max-width: 390px) {
  .header-phone-item-close {
    display: none;
  }
  .header__phone-img {
    scale: 1.1;
    padding: 15px;
  }
}
@media (max-width: 290px) {
  .header-phone-item-close {
    display: none;
  }
  .header__phone-img {
    scale: 1.1;
    padding: 5px;
  }
}
